<template>
  <v-app >
    <router-view />
  </v-app>
</template>

<style>
.draggable {
  -webkit-app-region: drag;
}

.no-draggable {
  -webkit-app-region: no-drag;
}

::-webkit-scrollbar {
  width: 0px;
  height: 4px; 
}

::-webkit-scrollbar-track {
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}
</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import * as LN from '@/util/requestApi.js'

export const overtPages = {
  welcome: [
    {
      name: '万事胜意',
      path: 'home1',
      module: 'welcome',
    },
    {
      name: '赛博朋克',
      path: 'home2',
      module: 'welcome',
    },
    {
      name: '星辰大海',
      path: 'home3',
      module: 'welcome',
    },
    {
      name: '平安喜乐',
      path: 'home4',
      module: 'welcome',
    },
    {
      name: '梦想成真',
      path: 'home5',
      module: 'welcome',
    },
  ]
}

export const secretPages = {
  manage: [
    {
      name: '万事胜意',
      path: 'home1',
      module: 'manage',
    },
    {
      name: '赛博朋克',
      path: 'home2',
      module: 'manage',
    },
    {
      name: '星辰大海',
      path: 'home3',
      module: 'manage',
    },
    {
      name: '平安喜乐',
      path: 'home4',
      module: 'manage',
    },
    {
      name: '梦想成真',
      path: 'home5',
      module: 'manage',
    },
  ]
}

const getRoutes = (pages, preurl) => {
  const routes = Object.values(pages).flat()
  return routes.map((e) => {
    const v = { path: `${e.module}/${e.path}`, name: `${e.module}-${e.name}` }
    v.component = () => import(`@/views/${preurl}/${e.module}/${e.path}.vue`)
    return v
  })
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    { path: '/', redirect: '/overt/welcome/home1' },
    {
      path: '/overt',
      component: () => import('@/views/layouts/Welcome.vue'),
      children: getRoutes(overtPages, 'overt')
    },
    {
      path: '/secret',
      component: () => import('@/views/layouts/Default.vue'),
      children: getRoutes(secretPages, 'secret')
    },
    {
      path: '/',
      component: () => import('@/views/layouts/Blank.vue'),
      children: [
        {
          path: '/private_login',
          component: () => import('@/views/Login.vue')
        },
        {
          path: '/private_home',
          redirect: '/secret/manage/home1'
        },
        {
          path: '/:pathMatch(.*)*',
          component: () => import('@/views/Error.vue'),
        },
      ]
    },
  ]
})

const is_valid_token = () => {
  const token = localStorage.getItem('app-token')
  if (token?.length) {
    LN.token.login.post_valid_token({ token: token }, null, null, (err) => {
      router.push('/private_login')
    })
    return true
  }
  return false
}

router.beforeEach((to, from, next) => {
  if (to.fullPath.startsWith('/secret') && !is_valid_token()) {
    next('/private_login')
  } else {
    next()
  }
})

export default router

// Current api version: dev-beta 
import { isRef } from 'vue' 

export const user = {}
user.consumer = {}
user.relation = {}

export const file = {}
file.audio = {}
file.text = {}
file.image = {}
file.video = {}

export const msg = {}
msg.object = {}
msg.ticket = {}
msg.crops = {}
msg.order = {}
msg.honor = {}
msg.shop = {}
msg.gps = {}
msg.config = {}
msg.farmer = {}
msg.test = {}
msg.hotel = {}
msg.advertise = {}
msg.money = {}
msg.product = {}
msg.navigation = {}
msg.celebrate = {}
msg.board = {}
msg.pomelo = {}
msg.score = {}

export const token = {}
token.register = {}
token.login = {}

const RcUrl = 'https://lnlin.com:17502'
const BaseUrl = 'https://lnlin.com:17001'

export const get_rc = (url, custom = null) => {
    if (url?.length) {
        if (custom == 'thumbnail') {
            let curl = url.replace("/image", "/thumbnail")
            return RcUrl + curl
        } else {
            return RcUrl + url
        }
    } else {
        return ''
    }
}

user.consumer.post_guess_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/guess_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_token_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/token_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_find_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/find_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_insert_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/insert_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_remove_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/remove_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_update_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/update_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_guess_user_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/guess_user_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.consumer.post_find_user_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/consumer/find_user_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

user.relation.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/user/relation/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.audio.put_audio_upload = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/audio/audio_upload", {
            method: 'put',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
            },
            body: param || ''
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.audio.post_audio_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/audio/audio_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.audio.post_audio_remove = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/audio/audio_remove", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.text.get_text_data = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const queryParams = new URLSearchParams(param || {}).toString()
        const fullUrl = BaseUrl + "/file/text/text_data" + (queryParams ? '?' + queryParams : '')
        const response = await fetch(fullUrl, {
            method: 'get',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            }
        })
        if (response.status == 200) {
            const data = await response.text()
            if (rdata && isRef(rdata)) {
                rdata.value = data
            }
            if (onsuccess) { onsuccess(data) }
            return data
        } else {
            if (onerror) { onerror('error') }
            return null
        }
    } catch (error) {
        if (onerror) { onerror(error) }
        return null
    }
}

file.text.post_text_upload = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/text/text_upload", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.text.put_text_upload = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/text/text_upload", {
            method: 'put',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
            },
            body: param || ''
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.image.put_image_upload = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/image/image_upload", {
            method: 'put',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
            },
            body: param || ''
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.image.post_image_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/image/image_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.image.post_image_remove = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/image/image_remove", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.video.put_video_upload = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/video/video_upload", {
            method: 'put',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
            },
            body: param || ''
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.video.post_video_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/video/video_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

file.video.post_video_remove = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/file/video/video_remove", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.object.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/object/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.ticket.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/ticket/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.crops.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/crops/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.order.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/order/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.honor.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/honor/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.shop.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/shop/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.gps.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/gps/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.config.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/config/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.farmer.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/farmer/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.test.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/test/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.hotel.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/hotel/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.advertise.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/advertise/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.money.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/money/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.product.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/product/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.navigation.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/navigation/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.celebrate.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/celebrate/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.board.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/board/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.pomelo.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/pomelo/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_guess_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/guess_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_find_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/find_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_insert_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/insert_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_remove_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/remove_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_update_item = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/update_item", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_uuid_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/uuid_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_name_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/name_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_guess_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/guess_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_guess_order_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/guess_order_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_guess_range_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/guess_range_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_guess_rules_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/guess_rules_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

msg.score.post_find_item_list = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/msg/score/find_item_list", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.register.post_user_register = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/register/user_register", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.register.post_create_cdkey = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/register/create_cdkey", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_exist_user = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/exist_user", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_visitor_login = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/visitor_login", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_farmer_login = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/farmer_login", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_admin_login = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/admin_login", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_role_login = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/role_login", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_user_login = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/user_login", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_user_logout = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/user_logout", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_valid_token = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/valid_token", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

token.login.post_valid_api = async (param = null, rdata = null, onsuccess = null, onerror = null) => {
    try {
        const response = await fetch(BaseUrl + "/token/login/valid_api", {
            method: 'post',
            headers: {
                'api-key': localStorage.getItem('app-token') || '',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param || {})
        })
        const json_data = await response.json()
        if (response.status == 200) {
            if (json_data.code == 200) {
                if (rdata && isRef(rdata)) {
                    rdata.value = json_data.data
                }
                if (onsuccess) { onsuccess(json_data.data) }
            } else {
                if (onerror) { onerror(json_data.message) }
            }
            return [json_data.code, json_data.message, json_data.data]
        } else {
            if (onerror) { onerror('serve error') }
            return [response.status, 'serve error', null]
        }
    } catch (error) {
        if (onerror) { onerror('network error') }
        return [404, 'network error', null]
    }
}

